<template>
  <div class="root">

    <ControlsComponent />

  </div>
</template>

<script>
import ControlsComponent from '../components/Controls.vue'

export default {
  name: 'Home',
  components: {
    ControlsComponent
  },
  mounted(){
    this.$store.commit( 'enablePopularSearches' )
  }
}
</script>

<style scoped>
  .root{
    position: relative;
    height: 850px;
  }
</style>