var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root",attrs:{"id":"root"}},[_c('div',{staticClass:"arrows",class:{ 
            'skew-left': _vm.buttonHover == 'L', 
            'skew-right': _vm.buttonHover == 'R',
            'go-up': _vm.buttonSelect == 'M'
        }}),_c('div',{staticClass:"options"},[_c('div',{staticClass:"title",class:{
                'go-left': _vm.buttonSelect != ''
            }},[_vm._v(" Choose your"),_c('br'),_vm._v(" big back drop! ")]),_c('div',{staticClass:"button material-button",class:{
                    'go-left': _vm.buttonSelect == 'M'
                },on:{"mouseover":_vm.hoverMaterial,"mouseleave":_vm.hoverNone,"click":_vm.selectMaterial}},[_vm._m(0)]),_c('div',{staticClass:"button banner-stand-button",class:{
                'go-right': _vm.buttonSelect == 'M'
            },on:{"mouseover":_vm.hoverStand,"click":_vm.selectBannerStand}},[_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Printed"),_c('br'),_vm._v(" Material"),_c('br'),_vm._v(" Backdrop ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" Free"),_c('br'),_vm._v(" Standing"),_c('br'),_vm._v(" Banner ")])}]

export { render, staticRenderFns }