<template>
  <div id="app">

    <transition name="fade" mode="out-in">
      <router-view>
      </router-view>
    </transition>

    <div class="popular-searches">
      <PopularSearchesComponenet />
    </div>

  </div>
</template>

<script>
import PopularSearchesComponenet from './components/PopularSearches.vue'

export default {
  components:{
    PopularSearchesComponenet
  },
  computed:{
    popularSearches:function(){
      return this.$store.state.popularSearches
    }
  },
  mounted: function() {
    this.$store.commit('enablePopularSearches')

    /**
     * Check for exiting cart items and if no cart check local storage
     */
    var basket = this.$store.getters.getBasket

    if( basket.length === 0 ) //the basket it empty
    {
      var localStorageBasket = JSON.parse( localStorage.getItem( 'basket' ) )
      this.$store.commit( 'setBasket', localStorageBasket )

      //find the highest id, add one and allocate as current product.
      var id = 0;
      localStorageBasket.forEach( product => {
        if( product.id > id )
        {
          id = product.id
        }
      })
      id++;
      
      this.$store.commit( 'setCurrentId', {id:id} )
    }
  },
  watch:{
    $route (){
        document.getElementById('app').removeAttribute("style")
    }
} 
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

  #app {
    width: 1272px;
    height: 850px;
    max-width: 100vw;
    margin: auto;
    position: relative;
    background: rgb(143,44,140);
    background: linear-gradient(0deg, rgba(143,44,140,1) 30%, rgba(7,6,7,1) 100%);
    font-family: 'Roboto', sans-serif;
    transition: height ease-in-out 2000ms;
    overflow: hidden;
  }

  .popular-searches{
    position: absolute;
    width: 100%;
    height: 20rem;
    bottom:0;
    background: white;
  }

  .patten{
      background: no-repeat;
      background-image: url( /images/background-patten.svg );
      position: absolute;
      background-position: center;
      z-index: 0;
  }
  .loc1{
      width: 300px;
      height: 300px;
      top: 1rem;
      left: 1rem;
      opacity: .1;
  }
  .loc2{
      width: 500px;
      height: 500px;
      max-width: calc(100% - 350px);
      bottom: 15rem;
      right: 1rem;
      opacity: .05;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }


  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

</style>
