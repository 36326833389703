import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import router from './router'
import Vuex from 'vuex'


Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueRouter)
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
      products: [
           
      ],
      currentProduct: {
          id: 0,
          type: '', //material or stand
          width: 1000,
          height: 1000,
          images: 0,
          cost: 0,
          image:{
            adobeStock: false,
            url: '',
            description: '',
            id: '',
          }
      },
      popularSearches: true,
  },
  mutations:{
    addProduct: (state, payload) => {
        state.products.push( payload )
    },
    deleteProduct: ( state, payload ) => {
        state.products = state.products.filter(function( product )
        {
            return ( product.id !== payload.id ) ? true : false;
        })

        localStorage.setItem( 'basket', JSON.stringify( state.products ) )
    },
    setType: ( state, payload ) => {
        state.currentProduct.type = payload.type
    },
    setSize: ( state, payload ) => {
        state.currentProduct.width = payload.width
        state.currentProduct.height = payload.height
    },
    setCost: (state, payload) => {
        state.currentProduct.cost = payload.cost
    },
    setStockImage: (state, payload) => {
        state.currentProduct.image.adobeStock = true
        state.currentProduct.image.url = payload.url
        state.currentProduct.image.description = payload.description
        state.currentProduct.image.id = payload.id
    },
    setCurrentId: (state, payload) => {
        state.currentProduct.id = payload.id
    },
    disablePopularSearches: ( state ) => {
        state.popularSearches = false
    },
    enablePopularSearches: ( state ) => {
        state.popularSearches = true
    },
    addProductToBasket: ( state ) => {
        var productJSON = JSON.stringify(state.currentProduct);
        var productCopy = JSON.parse( productJSON )

        state.products.push( productCopy )

        localStorage.setItem( 'basket', JSON.stringify( state.products ) )

        //reset current product
        state.currentProduct.id++ //next product number
        state.currentProduct.type = '' //material or stand
        state.currentProduct.width = 1000
        state.currentProduct.height = 1000
        state.currentProduct.images = 0
        state.currentProduct.cost = 0
        state.currentProduct.image.adobeStock = false
        state.currentProduct.image.url = ''
        state.currentProduct.image.description = ''
        state.currentProduct.image.id = ''
    },
    setBasket: (state, payload) => {
        state.products = payload
    }
  },
  getters: {
    getCurrentProduct: state => {
        return state.currentProduct
    },
    getBasket: state => {
        return state.products
    }
  },
});



new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')