import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/printed-material-backdrop',
    name: 'Printed Material Backdrops',
    component: () => import('../views/material/Select.vue')
  },
  {
    path: '/free-standing-banner',
    name: 'Free Standing Banner',
    component: () => import('../views/stand/Select.vue')
  },
  {
    path: '/printed-material-images',
    name: 'Printed Material Backdrops - Select Image',
    component: () => import('../views/material/Image.vue')
  },
  {
    path: '/basket',
    name: 'Big Backdrops - Basket',
    component: () => import('../views/Basket.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
