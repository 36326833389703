<template>
    <div class="root">
        <div class="title">
            Popular Searches
        </div>
        <div class="wrapper">
            <div class="images" id="images">
                <div v-for="i in images" :key="i.index">
                    <div class="image" :style="'background-image: url( /images/popular-searches/' + i + ')'"></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data: function(){
        return {
            images: [],
            allImages: [],
            diplayWidth: 0
        }
    },
    mounted: function(){
        this.axios.get('/popular-searches.json').then( (result) => { 
            this.images = result.data.files
            this.allImages = [...this.images]


            this.displayWidth = document.getElementById( 'images' ).offsetWidth
            let imagesWidth = this.images.length * 182 

            while( this.displayWidth > (imagesWidth - 182 ) ){
                this.images = this.images.concat( this.allImages )
                imagesWidth = this.images.length * 182 
            }
            
            setTimeout( this.updateImages, 2000 )
        } )
    },
    methods:{
        updateImages: function(){
            document.getElementById( 'images' ).style.transition = "transform 1000ms";
            document.getElementById( 'images' ).style.transform = "translate(-182px,0)"

            setTimeout( this.removeOldImage, 2000 )
        },
        removeOldImage: function(){
            this.images.shift()

            document.getElementById( 'images' ).style.transition = "transform 0ms";
            document.getElementById( 'images' ).style.transform = "translate(0,0)"
            
            let imagesWidth = this.images.length * 182 

            while( this.displayWidth > (imagesWidth - 182 ) ){
                this.images = this.images.concat( this.allImages )
                imagesWidth = this.images.length * 182 
            }

            setTimeout( this.updateImages, 2000 )
        }
    }
}
</script>

<style scoped>
    .title{
        font-size: 2rem;
        font-weight: 600;
        margin: 1rem 0 0 2rem;
    }
    .wrapper{
        overflow: hidden;
        height: 15rem;
    }
    .images{
        all:unset;
        display: flex;
        transform: translate(0,0);
    }
    .image{
        height: 150px;
        width: 150px;
        margin: 16px;
    }

    @media screen and (max-height:700px){
        .root{
            display: none;
        }
    }
</style>