<template>
    <div class="root" id="root">
        <div class="arrows" v-bind:class="{ 
                'skew-left': buttonHover == 'L', 
                'skew-right': buttonHover == 'R',
                'go-up': buttonSelect == 'M'
            }"></div>

        <div class="options">
            <div class="title" v-bind:class="{
                    'go-left': buttonSelect != ''
                }">

                Choose your<br />
                big back drop!
            </div>

            <div class="button material-button" 
                @mouseover="hoverMaterial" 
                @mouseleave="hoverNone" 
                @click="selectMaterial"
                v-bind:class="{
                        'go-left': buttonSelect == 'M'
                    }">
                <p>
                    Printed<br />
                    Material<br />
                    Backdrop
                </p>
            </div>
            <div class="button banner-stand-button" 
                @mouseover="hoverStand"
                @click="selectBannerStand"
                v-bind:class="{
                    'go-right': buttonSelect == 'M'
                }"
                >
                <p>
                    Free<br />
                    Standing<br />
                    Banner
                </p>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    data: function(){
        return{
            buttonHover: '',
            buttonSelect: ''
        }
    },
    methods: {
        hoverMaterial: function() {
            this.buttonHover = 'L'
        },
        hoverStand: function() {
            this.buttonHover = 'R'
        },
        hoverNone: function() {
            this.buttonHover = ''
        },
        selectMaterialPage: function() {
            this.$store.commit( 'setType', {type:'Printed Material Backdrop'} )
            this.$router.push({ path: '/printed-material-backdrop' })
        },
        selectStandPage: function() {
            this.$store.commit( 'setType', {type:'Free Standing Banner'} )
            this.$router.push({ path: '/free-standing-banner' })
        },
        selectMaterial: function() {
            this.buttonSelect = 'M'
            setTimeout( this.selectMaterialPage, 500 )
        },
        selectBannerStand: function() {
            this.buttonSelect = 'M'
            setTimeout( this.selectStandPage, 500 )
        }
    },
    mounted:function(){
        this.$nextTick(function () {
            document.getElementById('app').setAttribute("style", "height: 800px" )
        })
    }
}
</script>

<style scoped>
    .root{
        position: relative;
    }

    .options{
        position: relative;
    }

    .title{
        color: white;
        font-size: 3rem;
        font-weight: 700;
        position: absolute;
        top: 10rem;
        left: 5%
    }

    .button{
        margin-top: 7rem;
        background: no-repeat;
        background-position: center;
        background-size: cover;
        height: 325px;
        width: 325px;
        border-radius: 1rem;
        box-shadow: 1rem 1rem 2rem rgba(0,0,0,0.5);
        transition: 100ms transform;
        z-index: 1;
        position: absolute;
        top: 0rem;
    }

    .button:hover{
        box-shadow: .5rem .5rem 1rem rgba(0,0,0,0.8);
        transform: scale(0.98);
    }

    .button p{
        font-size: 3rem;
        font-weight: 700;
        color: rgb( 50, 50, 50 );
        position: absolute;
        left: 3.5rem;
        top: 2rem;
    }

    .material-button{
        background-image: url( /images/icon-printed-material.jpg );
        left: 35%

    }
    .banner-stand-button{
        background-image: url( /images/icon-banner-stand.jpg );
        left: 65%
    }
    .arrows{
        width: 25%;
        height: 4rem;
        background-image: url( /images/arrows.svg );
        background-repeat: no-repeat;
        position: absolute;
        left: 56%;
        top: 2rem;
        z-index: 2;
        transition: 100ms transform;
    }

    .skew-left{
        transform: skewX(20deg);
    }

    .skew-right{
        transform: skewX(-20deg);
    }

    .go-right{
        transition: 1000ms;
        opacity: 0;
        transform: translateX(100vw);
    }

    .go-left{
        transition: 1000ms;
        opacity: 0;
        transform: translateX(-100vw);
    }

    .go-up{
        transition: 1000ms;
        transform: translateY(-6rem);
        opacity: 0;
    }

    .new-printed-material-button{
        transition: 1500ms;
        left: 3rem;
    }

</style>